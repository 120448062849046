<template>
  <span
    class="user-hint mt-1 mb-2 "
    :class="!hideIcon ? 'd-flex' : ''"
  >
    <b-img
      v-if="!hideIcon"
      class="mr-50 ic-lightbulb"
      fluid
      src="@/assets/images/icons/ic-lightbulb.svg"
      alt="Popup guide"
    />
    <span
      v-if="isEcomdyPlatform"
      class="mb-0"
    >
      <span v-if="!hideQuote">{{ $t('home.maintenance.contentContactUs') }}</span>
      <span v-if="!hideContact">
        <span
          v-if="isExclusivePartner"
          data-testid="contact-messenger"
          class="text-message-color cursor-pointer"
          @click.prevent="goToMessengerUrl"
        >
          Messenger
        </span>
        <span
          v-else
          data-testid="contact-live-chat"
          class="underline cursor-pointer text-message-color"
          @click.prevent="goToSupport"
        >
          Live Chat
        </span>
      </span>
    </span>
    <span
      v-else
      class="mb-0"
    >
      <span v-if="!hideQuote">{{ $t('home.maintenance.contentContactUs') }}</span>
      <span v-if="!hideContact">
        <span
          v-if="discordUrl"
          class="text-message-color cursor-pointer"
          @click.prevent="goToDiscordUrl"
        >
          Discord
        </span>
        <span
          v-else-if="telegramUrl"
          class="underline cursor-pointer text-message-color"
          @click.prevent="goToTelegramUrl"
        >
          Telegram
        </span>
        <span
          v-else-if="instagramUrl"
          class="underline cursor-pointer text-message-color"
          @click.prevent="goToInstagramUrl"
        >
          Instagram
        </span>
        <span
          v-else-if="messengerUrl"
          class="text-message-color cursor-pointer"
          @click.prevent="goToMessengerUrl"
        >
          Messenger
        </span>
        <span
          v-else-if="whatsAppUrl"
          class="text-purple cursor-pointer"
          @click.prevent="goToWhatsApp"
        >
          What's App
        </span>
      </span>
    </span>
  </span>
</template>
<script>
import {
  BImg,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import paymentMixin from '@/mixins/paymentMixin'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'

export default {
  components: {
    BImg,
  },

  mixins: [toastification, paymentMixin, envMixin, stepGuideMixin, authMixin],

  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideQuote: {
      type: Boolean,
      default: false,
    },
    hideContact: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    goToSupport() {
      if (window.fcWidget?.isOpen() !== true) {
        window.fcWidget.open()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ic-lightbulb {
  background-color: var(--icon-document-color);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.text-message-color {
  color: var(--text-live-chat-color);
}
</style>
